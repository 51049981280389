import React from "react"
import kebabCase from 'lodash/kebabCase'
import './style.scss'
import { Link, StaticQuery, graphql } from "gatsby"

const PeriodList = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___date) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={data => {    
      const monthGrouppedData = data.allMarkdownRemark.group;
      let count = []
      let monthValue = []
      let yearValue = []
      let yearMonth = []

      for(let i = 0; i< monthGrouppedData.length; i++){

        let monthGroupdDateFieldValue = new Date(monthGrouppedData[i].fieldValue)
        
        monthValue.push(("0" + (monthGroupdDateFieldValue.getMonth() + 1)).slice(-2));
        yearValue.push(monthGroupdDateFieldValue.getFullYear());
        yearMonth.push(kebabCase(yearValue[i]) + "/" + kebabCase(monthValue[i]));
      }

      for(let i = 0; i < yearMonth.length; i++){
        let key = yearMonth[i];
        if(count[key] === undefined){
          count[key] = 0;
        }
          count[key]++;
      }
      
      let counts2 = []

      for (let key in count){
          counts2.push({
              number: count[key],
              year: key.slice(0, 4),
              month: key.slice(5, 7),
          })
      }

      let reverseCounts = counts2.reverse();

      return (
        <nav>
          <h1 className="periodlist-header">月別アーカイブ</h1>
          <ul className="period-list">         
            {reverseCounts.map(date => (
              <li id={date.number ? "display" : "displayNone"} className="period-list-item" key={date.month}>
                <span className="period-list-icon"></span>
                <Link className="period-list-item-link" to={`/${kebabCase(date.year)}/${kebabCase(date.month)}/`}>
                  {date.year}年{date.month}月({date.number})
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )
    }}
  />
)

export default PeriodList