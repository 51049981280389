import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import './style.scss'

export default function PopularList() {

    const data = useStaticQuery(graphql`
      query allPageViews {
        allPageViews {
          edges {
            node {
              totalCount
              id
            }
          }
        }
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 2000
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `);

    const posts = data.allMarkdownRemark.edges;

    const allPosts = data.allMarkdownRemark.edges;
    const appPageViews = data.allPageViews;
    const results = [];

    const modifiedAppPageViews = appPageViews.edges
    .sort(({ node: a }, { node: b }) => b.totalCount - a.totalCount) // countの大きい順にソートしている。
    .filter(({ node }) => node.id !== '/') // TOPページは除外する
    .slice(0, 10) // top 10の人気記事のみ抽出
    .forEach(({ node }) => {
      posts.forEach(({ node: post }) => {
        if (post.fields.slug === node.id) {
          results.push({
            count: node.totalCount,
            ...post,
          });
        }
      });
    });
    
   return (
      <div>
      <h1 className="popularlist-header">人気記事</h1>
      <table className="popular-list">       
        {results.map((result, i) => (
          <tbody key={result.frontmatter.title}>
            <tr key={result.frontmatter.title} className="popular-list-item">
              <td><span><b>{i+1}</b></span></td>  
              <td className="popular-list-item-box">
                <Link className="popular-list-item-box-link" to={result.fields.slug}>
                  <span style={{display: 'inline'}}>{result.frontmatter.title}</span>
                </Link>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
      </div>
    )
};