import React from "react"
import './style.scss'

const Search = () => {
    const cx = 'a5192b4640abbb2f9';
    let gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    let s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
    return (
        <div>
            <h1 className="search-header">サイト内検索</h1>
            <div className="gcse-search"></div>
        </div>
    )
}

export default Search